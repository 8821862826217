.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: rgb(255, 255, 202);
  /* background-image: url(../../../img/fon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left; */
  /* padding: 0; */
  /* background-attachment: fixed; */
}
@media (max-width: 600px) {
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    background-image: url(../../../img/fon5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    padding: 0;
    background-attachment: fixed;
  }
  .banner-hidden {
    display: none;
  }
}
@media (max-width: 500px) {
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    background-image: url(../../../img/fon5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    padding: 0;
    background-attachment: fixed;
  }
  .banner-hidden {
    display: none;
  }
}
