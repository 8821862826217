.price {
  background-image: url(../../../img/fon5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  padding-bottom: 35px;
}
.outsidewidthtable table {
  background-color: azure;
}
.font-upper {
  background-color: white;
  /* text-transform: uppercase; */
}
.price-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}
.price-title h1 {
  text-align: center;
  color: rgb(21, 4, 62);
}
.priceOne {
  text-align: center;
}
.other {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  color: black;
}
.price table {
  border: 0.1rem dotted gray;
  border-radius: 5px;
  margin: 0 auto;
  width: 70%;
  margin-bottom: 20px;
}
.thleft {
  text-align: left;
}
.price table tr th {
  width: 80%;
  border: 0.1rem dotted gray;
}
.price table tr td {
  border: 0.1rem dotted gray;
}
tr:nth-child(1) {
  background-color: rgb(194, 194, 247);
}
tr:nth-child(2n + 3) {
  background-color: #e3e3ff;
}
tr:nth-child(even) {
  background-color: #fffdfd;
}
.price table .center {
  text-align: center;
  vertical-align: middle;
}
.outsidewidthtable .lastquestion {
  max-width: 85%;
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 20px;
  padding-left: 15%;
}

.outsidewidthtable p span {
  display: block;
  padding: 10px;
  background-color: azure;
  border-radius: 10px;
  color: rgb(192, 1, 83);
  border-bottom: 2px dotted rgb(252, 93, 14);
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.outsidewidthtable .attantion {
  color: rgb(205, 5, 5);
  border-bottom: none;
}

@media screen and (max-width: 900px) {
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 650px;
    margin: 0 auto;
  }
  .mainContacts p {
    width: 500px;
    font-size: 18px;
  }
  .contacts {
    width: 500px;
  }
  .allphoto {
    width: 630px;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;
  }
  .allphoto img {
    width: 120px;
    border-radius: 10px;
    border: 1px solid rgb(2, 184, 2);
  }
  .allphoto .changephoto {
    width: 250px;
    transition: 0.3s;
  }
  .onerow {
    width: 620px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .tworow {
    width: 620px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .threerow,
  .fourrow {
    width: 620px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .font {
    font-size: 14px;
  }
}
@media screen and (max-width: 700px) {
  .font {
    font-size: 12px;
  }
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 500px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 615px) {
  .allphoto {
    width: 600px;
    cursor: default;
    transition: 0.3s;
  }
  .onerow {
    display: block;
    width: 300px;
    margin: 20px auto;
  }
  .allphoto .onerow img {
    width: 300px;
    margin: 20px auto;
  }
  .tworow {
    width: 300px;
    display: block;
    margin: 20px auto;
  }
  .allphoto .tworow img {
    width: 300px;
    height: 350px;
    margin: 20px auto;
  }
  .threerow,
  .fourrow {
    display: none;
  }
  .galaryh2 {
    text-align: center;
    padding-bottom: 20px;
    color: black;
  }
}
@media screen and (max-width: 590px) {
  .font {
    font-size: 10px;
  }
  .containerOnas h2 {
    box-shadow: 1px 1px 5px #021455;
    text-align: center;
    padding: 30px 0 20px;
  }
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 500px;
    margin: 0 auto;
    font-size: 14px;
  }
  .allphoto {
    cursor: auto;
    width: 250px;
    display: inline;
  }
  .allphoto img {
    width: 250px;
    margin-bottom: 20px;
  }
  .price {
    font-size: 14px;
  }
  .mainContacts p {
    width: 400px;
    font-size: 16px;
  }
  .contacts {
    padding-left: 0;
    width: 300px;
  }
  .contacts p {
    width: 300px;
    padding-left: 0;
  }
  .mainContacts h2 {
    margin: 20px auto;
  }
  .wrapcontacts .link {
    font-size: 16px;
  }
  .phone {
    width: 300px;
    padding-left: 0px;
    text-align: center;
  }
  .wrapcontacts p {
    padding-left: 0;
    margin-left: 0;
    text-align: center;
  }
  .vibertelegram {
    margin-left: 100px;
  }
  .mail {
    width: 300px;
  }
  .numerPhone {
    width: 350px;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 540px) {
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 400px;
    margin: 0 auto;
    font-size: 14px;
  }
}
@media screen and (max-width: 425px) {
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 300px;
    margin: 0 auto;
    font-size: 12px;
  }
  .containerOnas img {
    display: block;
    width: 300px;
    margin: 20px auto 20px;
  }
  .mainContacts p {
    width: 300px;
  }
  .contacts {
    padding-left: 0;
    width: 300px;
  }
}
@media screen and (max-width: 400px) {
  .outsidewidthtable p {
    width: 200px;
  }
  .outsidewidthtable .lastquestion {
    width: 85%;
  }
}
@media screen and (max-width: 370px) {
  .whatsapp img {
    padding-right: 0;
    padding-left: 130px;
    padding-top: 10px;
  }
  .whatsapp {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone span {
    width: 250px;
    font-size: 18px;
    padding-left: 70px;
    padding-top: 15px;
  }
  .numerPhone p {
    font-size: 20px;
  }
  .wrapcontacts .link {
    font-size: 20px;
  }
}
@media screen and (max-width: 330px) {
  .whatsapp img {
    padding-right: 0;
    padding-left: 110px;
    padding-top: 10px;
  }
  .whatsapp {
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone {
    width: 340px;
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
  }
  .numerPhone span {
    font-size: 18px;
    padding-left: 40px;
    padding-top: 15px;
  }
  .allphoto {
    width: 500px;
    cursor: default;
    transition: 0.3s;
  }
  .onerow {
    display: block;
    width: 250px;
    margin: 20px auto;
  }
  .allphoto .onerow img {
    width: 250px;
    margin: 20px auto;
  }
  .tworow {
    width: 250px;
    display: block;
    margin: 20px auto;
  }
  .allphoto .tworow img {
    width: 250px;
    height: 350px;
    margin: 20px auto;
  }
  .threerow {
    display: none;
  }
  .containerOnas p {
    text-indent: 40px;
    text-align: justify;
    width: 270px;
    margin: 0 auto;
    font-size: 12px;
  }
  .containerOnas img {
    display: block;
    width: 270px;
    margin: 20px auto 20px;
  }
  .mainContacts p {
    width: 250px;
    font-size: 14px;
  }
  .contacts {
    padding-left: 0;
    width: 250px;
  }
  .wrapcontacts .link {
    font-size: 13px;
  }
  .phone {
    width: 250px;
  }
  .vibertelegram {
    margin-left: 80px;
  }
  .mail {
    width: 250px;
  }
  .galaryh2 {
    text-align: center;
    padding-bottom: 0px;
    color: black;
  }
  .planpracy img {
    display: block;
    margin: 10px auto 10px;
    width: 250px;
  }
}
