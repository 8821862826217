.main-first {
  padding-bottom: 25px;
  background-image: url(../../../img/fon5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  margin: 0;
}
.main-gallery {
  padding-top: 20px;
}
