.galarry-block_1,
.galarry-block_4,
.galarry-block_2,
.galarry-block_3,
.galarry-block_5 {
  padding: 30px;
  background-color: white;

  border-radius: 10px;
}
.main-title-gallery {
  margin: 0px auto 45px auto;
  background-color: rgb(251, 255, 255);
  padding: 20px;
  border-radius: 20px;
  max-width: 800px;
  text-align: center;
}
.block {
  padding: 25px;
  background-color: aliceblue;
  text-align: center;
  border-radius: 20px;
  max-width: 40%;
  margin: 0 auto;
  position: relative;
  top: 10px;
  left: 0;
}
.special {
  padding: 30px;
  background-color: aliceblue;
  font-size: 18px;
  border-radius: 10px;
  min-height: 130px;
  margin: 35px 25px;
}

@media (max-width: 520px) {
  .main-title h2 {
    font-size: 22px;
  }
}
@media (max-width: 420px) {
  .main-title h2 {
    font-size: 19px;
  }
  .special {
    padding: 15px;
    font-size: 16px;
    min-height: 130px;
    margin: 15px 5px;
  }
  .block h3 {
    font-size: 14px;
  }
}
@media (max-width: 444px) {
  .swiper-pagination-fraction {
    bottom: 35px;
  }
  .special {
    font-size: 14px;
  }
}
@media (max-width: 314px) {
  .special {
    font-size: 12px;
  }
}
