.email__link {
  margin-top: 10px;
}
.email__link:hover {
  color: rgb(91, 118, 163);
}

@media (max-width: 450px) {
  .email__link {
    font-size: 13px;
  }
}
@media (max-width: 320px) {
  .email__link {
    font-size: 11px;
  }
}
